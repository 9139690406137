//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseLayout from "@/layouts/Base";
import MovieImage from "@/components/movie/Image";
import { profile_service, package_service } from "@/services";
export default {
  metaInfo: {
    title: "Submissions",
  },
  components: {
    BaseLayout,
    MovieImage,
  },
  data() {
    return {
      loading: true,
      submissions: [],
      packages: [],
    };
  },
  computed: {
    package_id_to_obj() {
      var map = {};
      this.packages.forEach((pack) => {
        map[pack.id] = pack;
      });
      return map;
    },
  },
  created() {
    this.get_packages();
    this.get_submissions();
  },
  methods: {
    has_active_package(order) {
      if (!order.package) {
        return false;
      }
      if (!this.package_id_to_obj[order.package]) {
        return false;
      }
      return this.package_id_to_obj[order.package].active;
    },
    is_not_paid(order) {
      return order.order_id && !order.payment_id;
    },
    get_packages() {
      package_service.get().then((data) => {
        this.packages.splice(0, this.packages.length);
        this.packages.push(...data.results);
      });
    },
    get_submissions() {
      profile_service
        .get({}, `${this.my_profile.id}/submissions`)
        .then((data) => {
          this.submissions.push(...data.results);
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.error_msg = this.decode_error_message(error);
        });
    },
  },
};
